import type {
  Analytics,
  AnalyticsBrowserSettings,
  InitOptions,
  Options,
  SegmentEvent,
} from '@arcadehq/analytics-next'
import { AnalyticsBrowser } from '@arcadehq/analytics-next'

import { isLocalEnv, isPreviewEnv, isStagingEnv } from '../helpers'
import { ANALYTICS_URL } from './constants'
import { PageTrackerOptions } from './hooks/types'
import { EventName, Events, PageName, PageProperties } from './pages'
import { propagateEvent } from './propagateEvent'

const analyticsUrl = new URL(ANALYTICS_URL)

const analyticsSettings: AnalyticsBrowserSettings = {
  writeKey: '',
  cdnSettings: {
    integrations: {
      'Segment.io': {
        // https://github.com/segmentio/analytics-next/blob/45cfa210f1367b229adbebe83d7e1c889a973386/src/plugins/segmentio/index.ts#L60
        apiHost: `${analyticsUrl.host}${analyticsUrl.pathname}`,
        protocol: analyticsUrl.protocol.split(':')[0],
      },
    },
  },
}

const analyticsOptions: InitOptions = {
  cookie: {
    sameSite: 'None',
    secure: true,
  },
}

class SegmentLoader {
  mock: boolean
  analytics: AnalyticsBrowser
  loaded: boolean = false

  constructor(mock: boolean) {
    this.mock = mock
    this.analytics = new AnalyticsBrowser()
  }

  init(options: InitOptions = {}) {
    if (this.mock) {
      return
    }

    if (this.loaded) {
      return
    }

    this.analytics.load(analyticsSettings, { ...analyticsOptions, ...options })
    this.loaded = true
  }

  identify: Analytics['identify'] = (userId, traits, options) => {
    const timestamp = new Date()
    return this.analytics.identify(userId, traits, {
      ...options,
      timestamp,
    })
  }

  page: Analytics['page'] = (category, name, properties, options) => {
    const timestamp = new Date()
    return this.analytics.page(category, name, properties, {
      ...options,
      timestamp,
    })
  }

  track: Analytics['track'] = (event, properties, options) => {
    const timestamp = new Date()
    return this.analytics.track(event, properties, {
      ...options,
      timestamp,
    })
  }

  trackLink: Analytics['trackLink'] = (links, event, properties, options) => {
    const timestamp = new Date()
    return this.analytics.trackLink(links, event, properties, {
      ...options,
      timestamp,
    })
  }
}

const isServerSide = typeof window === 'undefined'

// Mock Segment if:
// - Server-side
// - Vercel preview env (tracking gets rejected from those origins) except staging which gets its own tracking DB
// - Local env (remove `isLocal` to test tracking with local Pub/Sub emulator)
const shouldMockSegment =
  isServerSide || (isPreviewEnv() && !isStagingEnv()) || isLocalEnv()

let segment = new SegmentLoader(shouldMockSegment)

class EventTracker<PN extends PageName = PageName> {
  private userId: string | null
  private readonly pageName: PN
  private pageProperties: PageProperties[PN]
  private shouldPublishToHost: boolean
  private readonly options: Options

  // This is the Arcade-level Do Not Track flag.
  // It currently blocks setting _any_ cookie and `localStorage` values,
  // as well as sending any tracking events (even if otherwise anonymous).
  // It is overly agressive for most cases but changing that would be breaking
  // and would need careful migration.
  private doNotTrack: boolean

  // This is the browser-level Do Not Track setting.
  // It only disables setting uniquely-identifying cookies and `localStorage`
  // values. This is important for us to not be blocked by extensions like
  // Privacy Badger.
  // It is OK to track events as long as they're anonymous, and we can still use
  // cookies and `localStorage` for non-identifying purposes.
  private browserDoNotTrack: boolean

  constructor(
    pageName: PN,
    pageProperties: PageProperties[PN],
    options?: PageTrackerOptions
  ) {
    const {
      shouldPublishToHost = false,
      shouldAnonymizeIP = false,
      doNotTrack = false,
    } = options ?? {}

    this.userId = null
    this.pageName = pageName
    this.pageProperties = pageProperties
    this.shouldPublishToHost = shouldPublishToHost
    this.options = {
      ...(shouldAnonymizeIP ? { context: { ip: '0.0.0.0' } } : {}),
    }

    this.doNotTrack =
      doNotTrack || !!(typeof window !== 'undefined' && window._doNotTrack)

    this.browserDoNotTrack =
      typeof navigator !== 'undefined' ? navigator.doNotTrack === '1' : false

    if (!this.doNotTrack) {
      segment.init(
        this.browserDoNotTrack ? { disableClientPersistence: true } : {}
      )
    }
  }

  // Used in conjunction with `doNotTrack`. This allows us to enable tracking
  // only after a user consents to cookies.
  enableTracking() {
    this.doNotTrack = false
    this.browserDoNotTrack = false

    if (!segment.loaded) {
      segment.init()
    } else if (this.browserDoNotTrack) {
      // Segment was already loaded but without client persistence. We need
      // to re-initialize it in order to enable client persistance after
      // tracking was authorized.
      segment = new SegmentLoader(shouldMockSegment)
      segment.init()
    } else {
      // Segment was already loaded with client persistence, we don't
      // need to change anything.
    }
  }

  // Used by the preview component to prevent tracking events from being sent
  // when the user has gone into edit mode then preview mode again.
  setDoNotTrack(isEnabled: boolean) {
    this.doNotTrack = isEnabled
  }

  setPageProperties(pageProperties: PageProperties[PN]) {
    this.pageProperties = pageProperties
  }

  updatePageProperties(update: Partial<PageProperties[PN]>) {
    this.pageProperties = {
      ...this.pageProperties,
      ...update,
    }
  }

  updateShouldPublishToHost(shouldPublishToHost: boolean) {
    this.shouldPublishToHost = shouldPublishToHost
  }

  setUser(newUserId: string, newUserEmail: string | null) {
    if (this.userId !== newUserId) {
      this.userId = newUserId

      // We can't rely on the global Segment client to not be initialized because
      // any other instance of EventTracker without `doNotTrack` would initialize
      // it and then even the `doNotTrack` instances would be sending events,
      // so we need to double check that before actually calling Segment.
      if (!this.doNotTrack) {
        void segment.identify(newUserId, { email: newUserEmail }, this.options)
      }
    }
  }

  page() {
    if (!this.doNotTrack) {
      void segment.page(
        undefined,
        this.pageName,
        this.pageProperties,
        this.options
      )
    }

    if (this.shouldPublishToHost && this.pageName === 'Viewer') {
      propagateEvent(
        'Viewer',
        'Flow Rendered',
        this.pageProperties as PageProperties['Viewer'],
        {}
      )
    }
  }

  report<EN extends EventName>(
    eventName: EN,
    // Forbid `id` column because it conflicts with the event ID itself and breaks dbt
    eventProperties: Events[EN] & { id?: never }
  ) {
    if (!this.doNotTrack) {
      void segment.track(
        eventName as string,
        {
          ...this.pageProperties,
          ...eventProperties,
          name: this.pageName,
        },
        this.options
      )
    }
    if (this.shouldPublishToHost) {
      propagateEvent(
        this.pageName,
        eventName,
        this.pageProperties,
        eventProperties
      )
    }
  }

  attachLink<EN extends EventName>(
    anchorElement: HTMLAnchorElement,
    eventName: EventName,
    eventProperties: Events[EN]
  ) {
    if (!this.doNotTrack) {
      void segment.trackLink(
        anchorElement,
        eventName as string,
        {
          ...this.pageProperties,
          ...eventProperties,
        } as SegmentEvent['properties'],
        this.options
      )
    }
  }
}

export default EventTracker
