import CheckIcon from 'public/images/check.svg'
import CrossIcon from 'public/images/cross.svg'
import { Toaster, ToasterProps } from 'react-hot-toast'

import { LoaderType } from './loaders/type'
import Spinner from './Spinner'

export function ToasterDefaults({ toastOptions, ...props }: ToasterProps) {
  return (
    <Toaster
      gutter={4}
      position='bottom-center'
      containerStyle={{
        bottom: 24,
      }}
      toastOptions={{
        className:
          'pointer-events-none !rounded-lg !text-sm !font-medium !bg-gray-800 !max-w-[500px] !text-white !px-1 !min-h-[44px] !shadow-[0px_4px_10px_rgba(17,_24,_39,_0.08)]',
        style: {
          background: '#1f2937',
          color: '#ffffff',
        },
        success: {
          icon: <CheckIcon className='w-4 h-4 ml-2 shrink-0' />,
          iconTheme: {
            primary: '#FFFFFF',
            secondary: '#1f2937',
          },
        },
        error: {
          icon: <CrossIcon className='w-4 h-4 ml-2 shrink-0' />,
          iconTheme: {
            primary: '#FFFFFF',
            secondary: '#1f2937',
          },
        },
        loading: {
          icon: (
            <Spinner
              className='w-4 h-4 ml-2 shrink-0'
              color='#FFFFFF'
              secondaryColor='#1f2937'
              size={16}
              type={LoaderType.Oval}
            />
          ),
          iconTheme: {
            primary: '#FFFFFF',
            secondary: '#1f2937',
          },
        },
        ...toastOptions,
      }}
      {...props}
    />
  )
}
