import { useAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { Account } from 'src/auth/Account'
import { atoms } from 'src/state/atoms'

import EventTracker from '../EventTracker'
import { PageName, PageProperties } from '../pages'
import { PageTrackerOptions } from './types'

export function useCreateTracker<PN extends PageName>(
  pageName: PN,
  pageProperties: PageProperties[PN],
  pageTrackerOptions?: PageTrackerOptions,
  account?: Account
) {
  const [pageTracker, setPageTracker] = useAtom(atoms.pageTracker)

  const tracker = useMemo(
    () => {
      const tracker = new EventTracker<PN>(
        pageName,
        pageProperties,
        pageTrackerOptions
      )

      if (pageTracker) {
        const error = new Error('Page tracker already created')
        console.error(error)
        // Still allow creating a new sub-tracker, at least useful
        // for the edit page to create a `doNotTrack` tracker for the preview
        // component
        return tracker
      }

      // Set global page tracker only on the first created tracker
      setPageTracker(tracker)

      return tracker
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (account?.user.id) {
      tracker.setUser(account.user.id, account.user.email)
    }
  }, [account?.user.email, account?.user.id, tracker])

  useEffect(() => {
    tracker.updatePageProperties(pageProperties)
  }, [tracker, pageProperties])

  useEffect(() => {
    tracker.updateShouldPublishToHost(!!pageTrackerOptions?.shouldPublishToHost)
  }, [pageTrackerOptions?.shouldPublishToHost, tracker])

  useEffect(() => {
    tracker.setDoNotTrack(!!pageTrackerOptions?.doNotTrack)
  }, [pageTrackerOptions?.doNotTrack, tracker])

  return tracker
}
