import { useEffect } from 'react'
import useAccount from 'src/auth/useAccount'

import { PageName, PageProperties } from '../pages'
import { PageTrackerOptions } from './types'
import { useCreateTracker } from './useCreateTracker'

export function useCreatePageTracker<PN extends PageName>(
  pageName: PN,
  pageProperties: PageProperties[PN],
  pageTrackerOptions?: PageTrackerOptions
) {
  const account = useAccount()
  const tracker = useCreateTracker(
    pageName,
    pageProperties,
    pageTrackerOptions,
    account
  )

  useEffect(() => {
    tracker.page()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return tracker
}
