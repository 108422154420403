import pick from 'lodash/pick'

import { EventName, Events, PageName, PageProperties } from './pages'

type PublishableEvent<PN extends PageName, EN extends EventName> = {
  pageProperties: (keyof PageProperties[PN])[]
  eventProperties: (keyof Events[EN])[]
}

export const publishableEvents: {
  [PN in PageName]?: {
    [EN in EventName]?: PublishableEvent<PN, EN>
  }
} = {
  Viewer: {
    'Flow Rendered': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [],
    },
    'Hotspot Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId', 'hotspotId', 'hotspotLabel'],
    },
    // Legacy event for old CTA component
    'CTA Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId', 'ctaLabel'],
    },
    'Video Ended': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['stepId'],
    },
    'Flow Restarted': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [],
    },
    'Progress Bar Nav Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['fromStepId', 'toStepId'],
    },
    'Step Reached': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['fromStepId', 'isFinalStep', 'isInitialStep', 'stepId'],
    },
    'Overlay Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: [
        'buttonLabel',
        'isCta',
        'linkType',
        'linkUrl',
        'title',
        'subtitle',
        'stepId',
        'buttonId',
      ],
    },
    'External Url Clicked': {
      pageProperties: ['flowId', 'flowName'],
      eventProperties: ['linkUrl', 'stepId', 'source'],
    },
  },
}

type Event = {
  name: string
  event: object
}

const evts: Event[] = []
function flushEvents(evts: Event[], intervalId?: NodeJS.Timeout) {
  if (window?.__arcadeTracker?.track) {
    while (evts.length > 0) {
      const evt = evts.shift()
      evt && window.__arcadeTracker.track(evt?.name, evt?.event)
    }
    if (intervalId) {
      clearInterval(intervalId)
    }
  }
}
function track(name: Event['name'], event: Event['event']) {
  if (window?.__arcadeTracker?.track) {
    if (evts.length) {
      evts.push({ name, event })
      flushEvents(evts)
    } else {
      window.__arcadeTracker.track(name, event)
    }
  } else {
    evts.push({ name, event })
    const intervalId: NodeJS.Timeout = setInterval(
      () => flushEvents(evts, intervalId),
      1000
    )
  }
}

export function propagateEvent<PN extends PageName, EN extends EventName>(
  pageName: PN,
  eventName: EN,
  pageProperties: PageProperties[PN],
  eventProperties: Events[EN]
) {
  const pageEvents = publishableEvents[pageName]

  if (!pageEvents || !(eventName in (pageEvents as object))) return

  const eventDefinition = pageEvents[eventName] as PublishableEvent<PN, EN>
  if (typeof eventDefinition === 'undefined') return

  const event = {
    eventName,
    eventSource: 'Arcade',
    eventHostname: window.location.hostname,
    eventPathname: window.location.pathname,
    eventTimestamp: new Date().toISOString(),
    ...pick(pageProperties, eventDefinition.pageProperties),
    ...pick(eventProperties, eventDefinition.eventProperties),
  }

  window?.parent?.postMessage(event, '*')
  track(event.eventName as string, event)

  // Meta event "CTA Clicked".
  //
  // This is just derived from the existing "Overlay Clicked", "Powered by
  // Arcade Button Clicked" and "External Url Clicked" events.
  //
  // The underlying event name is exposed through `eventType`.
  if (
    (event.eventName === 'Overlay Clicked' &&
      (event as unknown as Events['Overlay Clicked']).isCta) ||
    event.eventName === 'Powered by Arcade Button Clicked' ||
    event.eventName === 'External Url Clicked' ||
    event.eventName === 'Form Answered'
  ) {
    const psuedoEvent = {
      ...event,
      eventName: 'CTA Clicked',
      eventType: event.eventName,
    }
    window?.parent?.postMessage(psuedoEvent, '*')
    track('CTA Clicked', psuedoEvent)
  }
}
