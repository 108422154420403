import Image, { ImageLoader, ImageProps } from 'next/legacy/image'
import { useMemo, useState } from 'react'
import { getBlurDataUrlFromBlurhash, isLocalEnv } from 'src/helpers'

/*
 * This component is based on the Image component from Next.js.
 * It automatically adds a default blurhash and leverage the Vercel
 * image loader.
 * see https://nextjs.org/docs/api-reference/next/image for reference.
 */

type Props = ImageProps & {
  key?: string | number | undefined
  width?: number
  height?: number
  blurhash?: string
  className?: string
  noBlur?: boolean
}

const CLOUDFLARE_DOMAIN = 'https://cdn.arcade.software'

export default function OptimizedImage(props: Props) {
  const { noBlur, ...imageProps } = props

  const [errored, setErrored] = useState(false)
  const blurProps = useMemo(() => {
    if (!props.blurDataURL && !noBlur) {
      return {
        placeholder: 'blur',
        blurDataURL: getBlurDataUrlFromBlurhash(
          props.blurhash,
          props.width,
          props.height
        ),
      } as Partial<Props>
    }
    return {}
  }, [props.blurDataURL, props.blurhash, props.height, props.width, noBlur])

  return (
    // eslint doesn't like the spread operator for alt text:
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      suppressHydrationWarning
      loader={errored ? fallbackLoader : cloudflareLoader}
      {...imageProps}
      {...blurProps}
      {...(isLocalEnv() ? { unoptimized: true } : null)}
      onError={() => setErrored(true)}
    />
  )
}

const cloudflareLoader: ImageLoader = ({ src, width, quality }) => {
  const sourceIsPhoto = src.endsWith('.jpg') || src.endsWith('.jpeg')
  quality = quality || (sourceIsPhoto ? 60 : undefined)

  if (/^https?:\/\//.test(src) && !isLocalEnv()) {
    const url = new URL(src)

    // Desktop local server URL, bypass
    if (url.hostname === 'localhost' || url.hostname === '127.0.0.1') {
      return src
    }

    const params = `fit=scale-down,format=auto,dpr=2,width=${width}${
      quality ? `,quality=${quality}` : ''
    }`
    return `${CLOUDFLARE_DOMAIN}/cdn-cgi/image/${params}/${normalizeSrc(src)}`
  }

  return src
}

const fallbackLoader: ImageLoader = ({ src }) => src

const normalizeSrc = (src: string) => {
  src = src.replace(CLOUDFLARE_DOMAIN, '')
  return src.startsWith('/') ? src.slice(1) : src
}
